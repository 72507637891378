import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { config } from "../config";
import { formatE164ToUSPhone } from "../ui2/common/formlib";
import { PURGE } from "redux-persist";
import { loadBusinessConfig } from "./account";
import { RootState } from "./store";

const computeHasChanges = (state: SettingsState) => {
  if (state.config === null || state.configType == "") {
    return;
  }

  if (state.configType === "promise_pay") {
    if (
      JSON.stringify(state.promisePayConfig) !== JSON.stringify(state.config)
    ) {
      state.hasChanges = true;
    } else {
      state.hasChanges = false;
    }
  } else if (state.configType === "home_services") {
    if (
      JSON.stringify(state.homeServicesConfig) !== JSON.stringify(state.config)
    ) {
      state.hasChanges = true;
    } else {
      state.hasChanges = false;
    }
  } else {
    if (state.standingBy !== !state.config.enabled) {
      state.hasChanges = true;
      return;
    }

    if (
      state.standbyNumber !==
      formatE164ToUSPhone(state.config.call_forward?.forward_to)
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      state.standbyForwardingMessage !== state.config.call_forward?.pre_greeting
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      state.detailsName !== state.config.business_details?.name ||
      state.detailsAddressLine1 !==
      state.config.business_details?.address_line1 ||
      state.detailsAddressLine2 !==
      state.config.business_details?.address_line2 ||
      state.detailsCity !== state.config.business_details?.city ||
      state.detailsState !== state.config.business_details?.state ||
      state.detailsZip !== state.config.business_details?.zip ||
      state.detailsPhone !== state.config.business_details?.phone ||
      state.detailsWebsite !== state.config.business_details?.website ||
      state.detailsEmail !== state.config.business_details?.email ||
      state.detailsAdditional !== state.config.business_details?.additional ||
      state.detailsGreeting !== state.config.business_details?.bot_greeting ||
      state.detailsGreetingOffHours !==
      state.config.business_details?.bot_greeting_off_hours ||
      state.detailsTimezone !== state.config.business_details?.timezone ||
      state.detailsFollowUpNeededWhen !==
      state.config.business_details?.owner_follow_up_when
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.faq) !==
      JSON.stringify(state.config.business_details?.faq || [])
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.directoryEntries) !==
      JSON.stringify(state.config.business_details?.directory_entries || [])
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      state.smsEnabled !== state.config.sms_config?.enabled ||
      JSON.stringify(state.smsLinks) !==
      JSON.stringify(state.config.sms_config?.sms_links || []) ||
      state.smsUponSilentCall !==
      state.config.sms_config?.sms_upon_silent_call ||
      state.silentCallSmsMessage !==
      state.config.sms_config?.silent_call_sms_message
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.serviceTitanConfig) !==
      JSON.stringify(state.config.service_titan_config)
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.calConfigV2) !==
      JSON.stringify(state.config.cal_config_v2)
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.operatingHoursConfig) !==
      JSON.stringify(state.config.operating_hours_config)
    ) {
      state.hasChanges = true;
      return;
    }

    if (
      JSON.stringify(state.appointmentConfig) !==
      JSON.stringify(state.config.appointment_config)
    ) {
      state.hasChanges = true;
      return;
    }

    state.hasChanges = false;
  }
};

export type SettingsState = {
  selectedNumber: string;
  settingsTabIndex: number;
  settingsTabVisibility: { [key: number]: boolean };
  config: any;
  configType: string;
  loadingRemote: boolean;
  remoteError: string;
  standingBy: boolean;
  standbyNumber: string;
  standbyForwardingMessage: string;
  detailsName: string;
  detailsAddressLine1: string;
  detailsAddressLine2: string;
  detailsCity: string;
  detailsState: string;
  detailsZip: string;
  detailsPhone: string;
  detailsWebsite: string;
  detailsEmail: string;
  detailsAdditional: string;
  detailsGreeting: string;
  detailsGreetingOffHours: string;
  detailsTimezone: string;
  detailsFollowUpNeededWhen: string;
  faq: { question: string; answer: string }[];
  directoryEntries: { name: string; phone: string; dispatch_when: string }[];
  smsEnabled: boolean;
  smsLinks: { url: string; description: string }[];
  smsUponSilentCall: boolean;
  silentCallSmsMessage: string;
  serviceTitanConfig: any;
  calConfigV2: any;
  promisePayConfig: any;
  homeServicesConfig: any;
  operatingHoursConfig: any;
  appointmentConfig: any;
  hasChanges: boolean;
};

const INITIAL_STATE: SettingsState = {
  selectedNumber: "",
  settingsTabIndex: 0,
  settingsTabVisibility: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  },
  config: null,
  configType: "",
  loadingRemote: false,
  remoteError: "",
  standingBy: false,
  standbyNumber: "",
  standbyForwardingMessage: "",
  detailsName: "",
  detailsAddressLine1: "",
  detailsAddressLine2: "",
  detailsCity: "",
  detailsState: "",
  detailsZip: "",
  detailsPhone: "",
  detailsWebsite: "",
  detailsEmail: "",
  detailsAdditional: "",
  detailsGreeting: "",
  detailsGreetingOffHours: "",
  detailsTimezone: "US/Pacific",
  detailsFollowUpNeededWhen: "",
  faq: [],
  directoryEntries: [],
  smsEnabled: false,
  smsLinks: [],
  smsUponSilentCall: false,
  silentCallSmsMessage: "",
  serviceTitanConfig: null,
  calConfigV2: null,
  promisePayConfig: null,
  homeServicesConfig: null,
  operatingHoursConfig: null,
  appointmentConfig: null,
  hasChanges: false,
};

export const slice = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => INITIAL_STATE);
  },
  reducers: {
    setSelectedNumber: (state, action) => {
      state.selectedNumber = action.payload;
    },
    setSettingsTabIndex: (state, action) => {
      state.settingsTabIndex = action.payload;
    },
    disableSettingsTab: (state, action) => {
      state.settingsTabVisibility[action.payload] = false;
    },
    enableSettingsTab: (state, action) => {
      state.settingsTabVisibility[action.payload] = true;
    },
    setConfig: (state, action) => {
      const configType = action.payload.config_type;

      if (configType === "promise_pay") {
        return {
          ...state,
          configType: configType,
          hasChanges: false,
          config: action.payload,
          promisePayConfig: {
            ...action.payload,
            standby_number: formatE164ToUSPhone(action.payload.standby_number),
          },
        };
      } else if (configType === "home_services") {
        return {
          ...state,
          configType: configType,
          hasChanges: false,
          config: action.payload,
          homeServicesConfig: {
            ...action.payload,
            bot_enabled_config: {
              ...action.payload.bot_enabled_config,
              transfer_to: formatE164ToUSPhone(
                action.payload.bot_enabled_config.transfer_to,
              ),
            },
          },
        };
      } else {
        return {
          ...state,
          settingsTabIndex: 0,
          settingsTabVisibility: {
            0: true,
            1: true,
            2: true,
            3: true,
            4: true,
            5: action.payload.service_titan_config ? true : false,
            6: ["+16503040524", "+18056000188", "+16506291521", "+16504051384"].includes(
              state.selectedNumber,
            ),
            7: true,
          },
          config: action.payload,
          configType: action.payload.config_type,
          standingBy: !action.payload.enabled,
          standbyNumber: formatE164ToUSPhone(
            action.payload.call_forward?.forward_to || "",
          ),
          standbyForwardingMessage:
            action.payload.call_forward?.pre_greeting || "",
          detailsName: action.payload.business_details?.name || "",
          detailsAddressLine1:
            action.payload.business_details?.address_line1 || "",
          detailsAddressLine2:
            action.payload.business_details?.address_line2 || "",
          detailsCity: action.payload.business_details?.city || "",
          detailsState: action.payload.business_details?.state || "",
          detailsZip: action.payload.business_details?.zip || "",
          detailsPhone: action.payload.business_details?.phone || "",
          detailsWebsite: action.payload.business_details?.website || "",
          detailsEmail: action.payload.business_details?.email || "",
          detailsAdditional: action.payload.business_details?.additional || "",
          detailsGreeting: action.payload.business_details?.bot_greeting || "",
          detailsGreetingOffHours:
            action.payload.business_details?.bot_greeting_off_hours || "",
          detailsTimezone:
            action.payload.business_details?.timezone || "US/Pacific",
          detailsFollowUpNeededWhen:
            action.payload.business_details?.owner_follow_up_when || "",
          faq: action.payload.business_details?.faq || [],
          directoryEntries:
            action.payload.business_details?.directory_entries || [],
          smsEnabled: action.payload.sms_config?.enabled || false,
          smsLinks: action.payload.sms_config?.sms_links || [],
          smsUponSilentCall:
            action.payload.sms_config?.sms_upon_silent_call || false,
          silentCallSmsMessage:
            action.payload.sms_config?.silent_call_sms_message || "",
          serviceTitanConfig: action.payload.service_titan_config || null,
          calConfigV2: action.payload.cal_config_v2 || null,
          operatingHoursConfig: action.payload.operating_hours_config || null,
          appointmentConfig: action.payload.appointment_config || null,
          hasChanges: false,
        };
      }
    },
    setLoadingRemote: (state, action) => {
      state.loadingRemote = action.payload;
    },
    setRemoteError: (state, action) => {
      state.remoteError = action.payload;
    },
    setStandingBy: (state, action) => {
      state.standingBy = action.payload;
      computeHasChanges(state);
    },
    setStandbyNumber: (state, action) => {
      state.standbyNumber = action.payload;
      computeHasChanges(state);
    },
    setStandbyForwardingMessage: (state, action) => {
      state.standbyForwardingMessage = action.payload;
      computeHasChanges(state);
    },
    setDetailsName: (state, action) => {
      state.detailsName = action.payload;
      computeHasChanges(state);
    },
    setDetailsAddressLine1: (state, action) => {
      state.detailsAddressLine1 = action.payload;
      computeHasChanges(state);
    },
    setDetailsAddressLine2: (state, action) => {
      state.detailsAddressLine2 = action.payload;
      computeHasChanges(state);
    },
    setDetailsCity: (state, action) => {
      state.detailsCity = action.payload;
      computeHasChanges(state);
    },
    setDetailsState: (state, action) => {
      state.detailsState = action.payload;
      computeHasChanges(state);
    },
    setDetailsZip: (state, action) => {
      state.detailsZip = action.payload;
      computeHasChanges(state);
    },
    setDetailsPhone: (state, action) => {
      state.detailsPhone = action.payload;
      computeHasChanges(state);
    },
    setDetailsWebsite: (state, action) => {
      state.detailsWebsite = action.payload;
      computeHasChanges(state);
    },
    setDetailsEmail: (state, action) => {
      state.detailsEmail = action.payload;
      computeHasChanges(state);
    },
    setDetailsAdditional: (state, action) => {
      state.detailsAdditional = action.payload;
      computeHasChanges(state);
    },
    setDetailsGreeting: (state, action) => {
      state.detailsGreeting = action.payload;
      computeHasChanges(state);
    },
    setDetailsGreetingOffHours: (state, action) => {
      state.detailsGreetingOffHours = action.payload;
      computeHasChanges(state);
    },
    setDetailsTimezone: (state, action) => {
      state.detailsTimezone = action.payload;
      computeHasChanges(state);
    },
    setDetailsFollowUpNeededWhen: (state, action) => {
      state.detailsFollowUpNeededWhen = action.payload;
      computeHasChanges(state);
    },
    addFaq: (state, action) => {
      if (action.payload) {
        state.faq.push(action.payload);
      } else {
        state.faq.push({ question: "", answer: "" });
      }
      computeHasChanges(state);
    },
    updateFaqQuestion: (state, action) => {
      state.faq[action.payload.index].question = action.payload.question;
      computeHasChanges(state);
    },
    updateFaqAnswer: (state, action) => {
      state.faq[action.payload.index].answer = action.payload.answer;
      computeHasChanges(state);
    },
    deleteFaq: (state, action) => {
      state.faq.splice(action.payload, 1);
      computeHasChanges(state);
    },
    addDirectoryEntry: (state, action) => {
      if (action.payload) {
        state.directoryEntries.push(action.payload);
      } else {
        state.directoryEntries.push({ name: "", phone: "", dispatch_when: "" });
      }
      computeHasChanges(state);
    },
    updateDirectoryEntryName: (state, action) => {
      state.directoryEntries[action.payload.index].name = action.payload.name;
      computeHasChanges(state);
    },
    updateDirectoryEntryPhone: (state, action) => {
      state.directoryEntries[action.payload.index].phone = action.payload.phone;
      computeHasChanges(state);
    },
    updateDirectoryEntryDispatchWhen: (state, action) => {
      state.directoryEntries[action.payload.index].dispatch_when =
        action.payload.dispatch_when;
      computeHasChanges(state);
    },
    deleteDirectoryEntry: (state, action) => {
      state.directoryEntries.splice(action.payload, 1);
      computeHasChanges(state);
    },
    setSmsEnabled: (state, action) => {
      state.smsEnabled = action.payload;
      computeHasChanges(state);
    },
    setSmsUponSilentCall: (state, action) => {
      state.smsUponSilentCall = action.payload;
      computeHasChanges(state);
    },
    setSilentCallSmsMessage: (state, action) => {
      state.silentCallSmsMessage = action.payload;
      computeHasChanges(state);
    },
    addSmsLink: (state, action) => {
      if (action.payload) {
        state.smsLinks.push(action.payload);
      } else {
        state.smsLinks.push({ url: "", description: "" });
      }
      computeHasChanges(state);
    },
    updateSmsLinkUrl: (state, action) => {
      state.smsLinks[action.payload.index].url = action.payload.url;
      computeHasChanges(state);
    },
    updateSmsLinkDescription: (state, action) => {
      state.smsLinks[action.payload.index].description =
        action.payload.description;
      computeHasChanges(state);
    },
    deleteSmsLink: (state, action) => {
      state.smsLinks.splice(action.payload, 1);
      computeHasChanges(state);
    },
    // ServiceTitan
    updateServiceTitanConfig: (state, action) => {
      state.serviceTitanConfig = action.payload;
      computeHasChanges(state);
    },
    // Calendars
    updateCalConfigV2: (state, action) => {
      state.calConfigV2 = action.payload;
      computeHasChanges(state);
    },
    updatePromisePayConfig: (state, action) => {
      state.promisePayConfig = action.payload;
      computeHasChanges(state);
    },
    updateHomeServicesConfig: (state, action) => {
      state.homeServicesConfig = action.payload;
      computeHasChanges(state);
    },
    updateOperatingHoursConfig: (state, action) => {
      state.operatingHoursConfig = action.payload;
      computeHasChanges(state);
    },
    updateAppointmentConfig: (state, action) => {
      state.appointmentConfig = action.payload;
      computeHasChanges(state);
    },
  },
});

export const choosePhoneNumber = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("settings/choosePhoneNumber", async (number, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingRemote(true));
    const state = thunkAPI.getState();
    if (state.account.auth) {
      const response = await fetch(config.base_url + `/api/phones/${number}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.account.auth.access_token,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          thunkAPI.dispatch(setRemoteError("Unauthorized"));
        } else {
          thunkAPI.dispatch(
            setRemoteError("Invalid response fetching phone config"),
          );
        }
      } else {
        const data = await response.json();
        const phoneConfig = data.bot_config;
        thunkAPI.dispatch(setSelectedNumber(number));
        thunkAPI.dispatch(setConfig(phoneConfig));
        thunkAPI.dispatch(setRemoteError(""));
      }
    } else {
      thunkAPI.dispatch(setRemoteError("Unauthenticated"));
    }
  } catch (e) {
    thunkAPI.dispatch(setRemoteError("Error fetching phone config"));
  } finally {
    thunkAPI.dispatch(setLoadingRemote(false));
  }
});

export const savePhoneNumber = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("settings/savePhoneNumber", async (_arg, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingRemote(true));
    const state = thunkAPI.getState();
    if (!state.settings.selectedNumber) {
      thunkAPI.dispatch(setRemoteError("No phone number selected"));
      return;
    }
    if (state.account.auth) {
      let newConfig;
      if (state.settings.configType === "promise_pay") {
        newConfig = state.settings.promisePayConfig;
      } else if (state.settings.configType === "home_services") {
        newConfig = state.settings.homeServicesConfig;
      } else {
        newConfig = {
          ...state.settings.config,
          enabled: !state.settings.standingBy,
          call_forward: {
            ...(state.settings.config.call_forward || {}),
            forward_to: state.settings.standbyNumber,
            pre_greeting: state.settings.standbyForwardingMessage,
          },
          business_details: {
            ...(state.settings.config.business_details || {}),
            name: state.settings.detailsName,
            address_line1: state.settings.detailsAddressLine1,
            address_line2: state.settings.detailsAddressLine2,
            city: state.settings.detailsCity,
            state: state.settings.detailsState,
            zip: state.settings.detailsZip,
            phone: state.settings.detailsPhone,
            website: state.settings.detailsWebsite,
            email: state.settings.detailsEmail,
            additional: state.settings.detailsAdditional,
            bot_greeting: state.settings.detailsGreeting,
            bot_greeting_off_hours: state.settings.detailsGreetingOffHours,
            timezone: state.settings.detailsTimezone,
            owner_follow_up_when: state.settings.detailsFollowUpNeededWhen,
            faq: state.settings.faq,
            directory_entries: state.settings.directoryEntries,
          },
          sms_config: {
            ...(state.settings.config.sms_config || {}),
            enabled: state.settings.smsEnabled,
            sms_links: state.settings.smsLinks,
            sms_upon_silent_call: state.settings.smsUponSilentCall,
            silent_call_sms_message: state.settings.silentCallSmsMessage,
          },
          service_titan_config: state.settings.serviceTitanConfig,
          cal_config_v2: state.settings.calConfigV2,
          operating_hours_config: state.settings.operatingHoursConfig,
          appointment_config: state.settings.appointmentConfig,
        };
      }

      const response = await fetch(
        config.base_url + `/api/phones/${state.settings.selectedNumber}/config`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.account.auth.access_token,
          },
          body: JSON.stringify(newConfig),
        },
      );
      if (!response.ok) {
        if (response.status === 401) {
          thunkAPI.dispatch(setRemoteError("Unauthorized"));
        } else {
          thunkAPI.dispatch(
            setRemoteError("Invalid response saving phone config"),
          );
        }
      } else {
        await response.json();
        thunkAPI.dispatch(setConfig(newConfig));
        thunkAPI.dispatch(setRemoteError(""));
      }
    } else {
      thunkAPI.dispatch(setRemoteError("Unauthenticated"));
    }
  } catch (e) {
    thunkAPI.dispatch(setRemoteError("Error saving phone config"));
  } finally {
    thunkAPI.dispatch(setLoadingRemote(false));
  }
});

export const saveTrialPhoneNumber = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("settings/saveTrialPhoneNumber", async (_arg, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingRemote(true));
    const state = thunkAPI.getState();

    const businessId = state.admin.forceBusinessId || state.account.businessId;

    const businessConfig = state.account.businessConfig;

    if (
      businessConfig?.subscription?.subscription_type !== "trial" &&
      businessConfig?.subscription?.subscription_type !== "trial_pin"
    ) {
      thunkAPI.dispatch(setRemoteError("Not a trial account"));
      return;
    }

    if (!state.settings.selectedNumber) {
      thunkAPI.dispatch(setRemoteError("No phone number selected"));
      return;
    }
    if (state.account.auth) {
      let newConfig;
      if (state.settings.configType === "promise_pay") {
        newConfig = state.settings.promisePayConfig;
      } else if (state.settings.configType === "home_services") {
        newConfig = state.settings.homeServicesConfig;
      } else {
        newConfig = {
          ...state.settings.config,
          enabled: !state.settings.standingBy,
          call_forward: {
            ...(state.settings.config.call_forward || {}),
            forward_to: state.settings.standbyNumber,
            pre_greeting: state.settings.standbyForwardingMessage,
          },
          business_details: {
            ...(state.settings.config.business_details || {}),
            name: state.settings.detailsName,
            address_line1: state.settings.detailsAddressLine1,
            address_line2: state.settings.detailsAddressLine2,
            city: state.settings.detailsCity,
            state: state.settings.detailsState,
            zip: state.settings.detailsZip,
            phone: state.settings.detailsPhone,
            website: state.settings.detailsWebsite,
            email: state.settings.detailsEmail,
            additional: state.settings.detailsAdditional,
            bot_greeting: state.settings.detailsGreeting,
            bot_greeting_off_hours: state.settings.detailsGreetingOffHours,
            timezone: state.settings.detailsTimezone,
            owner_follow_up_when: state.settings.detailsFollowUpNeededWhen,
            faq: state.settings.faq,
            directory_entries: state.settings.directoryEntries,
          },
          sms_config: {
            ...(state.settings.config.sms_config || {}),
            enabled: state.settings.smsEnabled,
            sms_links: state.settings.smsLinks,
            sms_upon_silent_call: state.settings.smsUponSilentCall,
            silent_call_sms_message: state.settings.silentCallSmsMessage,
          },
          service_titan_config: state.settings.serviceTitanConfig,
          cal_config_v2: state.settings.calConfigV2,
          operating_hours_config: state.settings.operatingHoursConfig,
          appointment_config: state.settings.appointmentConfig,
        };
      }

      const response = await fetch(
        config.base_url + `/api/businesses/${businessId}/trial_bot_config`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.account.auth.access_token,
          },
          body: JSON.stringify({
            // from_number: businessConfig.subscription.from_number,
            bot_config: newConfig,
          }),
        },
      );
      if (!response.ok) {
        if (response.status === 401) {
          thunkAPI.dispatch(setRemoteError("Unauthorized"));
        } else {
          thunkAPI.dispatch(
            setRemoteError("Invalid response saving phone config"),
          );
        }
      } else {
        const data = await response.json();
        if (!data) {
          thunkAPI.dispatch(setRemoteError("Error saving phone config" + data));
        } else {
          if (businessId) {
            thunkAPI.dispatch(loadBusinessConfig(businessId));
          }
        }
      }
    }
  } catch (e) {
    thunkAPI.dispatch(setRemoteError("Error saving phone config" + e));
  } finally {
    thunkAPI.dispatch(setLoadingRemote(false));
  }
});

export const {
  setSelectedNumber,
  setSettingsTabIndex,
  disableSettingsTab,
  enableSettingsTab,
  setConfig,
  setStandingBy,
  setStandbyNumber,
  setStandbyForwardingMessage,
  setDetailsName,
  setDetailsAddressLine1,
  setDetailsAddressLine2,
  setDetailsCity,
  setDetailsState,
  setDetailsZip,
  setDetailsPhone,
  setDetailsWebsite,
  setDetailsEmail,
  setDetailsAdditional,
  setDetailsGreeting,
  setDetailsGreetingOffHours,
  setDetailsTimezone,
  setDetailsFollowUpNeededWhen,
  setRemoteError,
  setLoadingRemote,
  addFaq,
  updateFaqQuestion,
  updateFaqAnswer,
  deleteFaq,
  addDirectoryEntry,
  updateDirectoryEntryName,
  updateDirectoryEntryPhone,
  updateDirectoryEntryDispatchWhen,
  deleteDirectoryEntry,
  setSmsEnabled,
  setSmsUponSilentCall,
  setSilentCallSmsMessage,
  addSmsLink,
  updateSmsLinkUrl,
  updateSmsLinkDescription,
  deleteSmsLink,
  updateServiceTitanConfig,
  updateCalConfigV2,
  updatePromisePayConfig,
  updateHomeServicesConfig,
  updateOperatingHoursConfig,
  updateAppointmentConfig,
} = slice.actions;

export const selectSettingsTabIndex = (state: RootState) =>
  state.settings.settingsTabIndex;
export const selectSettingsTabVisibility = (state: RootState) =>
  state.settings.settingsTabVisibility;
export const selectConfig = (state: RootState) => state.settings.config;
export const selectConfigType = (state: RootState) => state.settings.configType;
export const selectLoadingRemote = (state: RootState) =>
  state.settings.loadingRemote;
export const selectRemoteError = (state: RootState) =>
  state.settings.remoteError;
export const selectStandingBy = (state: RootState) => state.settings.standingBy;
export const selectStandbyNumber = (state: RootState) =>
  state.settings.standbyNumber;
export const selectStandbyForwardingMessage = (state: RootState) =>
  state.settings.standbyForwardingMessage;
export const selectDetailsName = (state: RootState) =>
  state.settings.detailsName;
export const selectDetailsAddressLine1 = (state: RootState) =>
  state.settings.detailsAddressLine1;
export const selectDetailsAddressLine2 = (state: RootState) =>
  state.settings.detailsAddressLine2;
export const selectDetailsCity = (state: RootState) =>
  state.settings.detailsCity;
export const selectDetailsState = (state: RootState) =>
  state.settings.detailsState;
export const selectDetailsZip = (state: RootState) => state.settings.detailsZip;
export const selectDetailsPhone = (state: RootState) =>
  state.settings.detailsPhone;
export const selectDetailsWebsite = (state: RootState) =>
  state.settings.detailsWebsite;
export const selectDetailsEmail = (state: RootState) =>
  state.settings.detailsEmail;
export const selectDetailsAdditional = (state: RootState) =>
  state.settings.detailsAdditional;
export const selectDetailsGreeting = (state: RootState) =>
  state.settings.detailsGreeting;
export const selectDetailsGreetingOffHours = (state: RootState) =>
  state.settings.detailsGreetingOffHours;
export const selectDetailsTimezone = (state: RootState) =>
  state.settings.detailsTimezone;
export const selectDetailsFollowUpNeededWhen = (state: RootState) =>
  state.settings.detailsFollowUpNeededWhen;
export const selectFaq = (state: RootState) => state.settings.faq;
export const selectDirectoryEntries = (state: RootState) =>
  state.settings.directoryEntries;
export const selectSmsEnabled = (state: RootState) => state.settings.smsEnabled;
export const selectSmsLinks = (state: RootState) => state.settings.smsLinks;
export const selectSmsUponSilentCall = (state: RootState) =>
  state.settings.smsUponSilentCall;
export const selectSilentCallSmsMessage = (state: RootState) =>
  state.settings.silentCallSmsMessage;
export const selectServiceTitanConfig = (state: RootState) =>
  state.settings.serviceTitanConfig;
export const selectCalConfigV2 = (state: RootState) =>
  state.settings.calConfigV2;
export const selectHasChanges = (state: RootState) => state.settings.hasChanges;
export const selectSelectedNumber = (state: RootState) =>
  state.settings.selectedNumber;
export const selectPromisePayConfig = (state: RootState) =>
  state.settings.promisePayConfig;
export const selectHomeServicesConfig = (state: RootState) =>
  state.settings.homeServicesConfig;
export const selectOperatingHoursConfig = (state: RootState) =>
  state.settings.operatingHoursConfig;
export const selectAppointmentConfig = (state: RootState) =>
  state.settings.appointmentConfig;

export default slice.reducer;
