import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { RootState } from "./store";

export type AdminState = {
  forceBusinessId?: number;
};

const INITIAL_STATE: AdminState = {};

export const slice = createSlice({
  name: "admin",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => INITIAL_STATE);
  },
  reducers: {
    setForceBusinessId: (state, action) => {
      state.forceBusinessId = action.payload;
    },
  },
});

export const { setForceBusinessId } = slice.actions;

export const selectForceBusinessId = (state: RootState) =>
  state.admin?.forceBusinessId;

export default slice.reducer;
