import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { PURGE } from "redux-persist";
import { RootState } from "./store";
import {
  Business,
  BusinessRole,
  BusinessConfig,
  UserBusinessConfig,
} from "@repo/common/types/business_config";
import { Token, UserSiteRole } from "@repo/common/types/auth";

export type AccountState = {
  userId?: number;
  userEmail?: string | null;
  userSiteRole?: UserSiteRole;
  userDeactivate: boolean;
  userDeactivateRequestDate?: string | null;
  businessId?: number;
  businessName?: string;
  userBusinessRole?: BusinessRole;
  businesses: {
    business: Business;
    user_business_config: UserBusinessConfig;
  }[];
  accessToken?: string;
  accessTokenType?: string;
  auth?: Token;
  businessConfig?: BusinessConfig;
  businessConfigLoaded: boolean;
  uniqueId?: string;
};

const INITIAL_STATE: AccountState = {
  userDeactivate: false,
  businesses: [],
  businessConfigLoaded: false,
  uniqueId: uuidv4(),
};

export const slice = createSlice({
  name: "account",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => INITIAL_STATE);
  },
  reducers: {
    setAuth: (state, action: PayloadAction<Token>) => {
      state.auth = action.payload;
      state.userEmail = action.payload.user.email;
      state.userId = action.payload.user.id;
      state.businessId = action.payload.businesses[0].business.id;
      state.businessName = action.payload.businesses[0].business.name;
      state.userBusinessRole =
        action.payload.businesses[0].user_business_config.role;
      state.businesses = action.payload.businesses;
      state.accessToken = action.payload.access_token;
      state.accessTokenType = action.payload.token_type;
      state.userSiteRole = action.payload.user.data.site_role;
      state.userDeactivate = action.payload.user.data.deactivate;
      state.userDeactivateRequestDate =
        action.payload.user.data.deactivate_request_date;
    },

    setBusinessConfig: (state, action) => {
      state.businessConfig = action.payload;
      state.businessConfigLoaded = true;
    },

    initUniqueId: (state) => {
      if (!state.uniqueId) {
        state.uniqueId = uuidv4();
      }
    },
  },
});

export const { setAuth, setBusinessConfig, initUniqueId } = slice.actions;
export default slice.reducer;

// Selectors
export const selectUserId = (state: RootState) => state.account.userId;
export const selectUserEmail = (state: RootState) => state.account.userEmail;
export const selectBusinessId = (state: RootState) => state.account.businessId;
export const selectAccessToken = (state: RootState) =>
  state.account.accessToken;
export const selectAccessTokenType = (state: RootState) =>
  state.account.accessTokenType;
export const selectUserSiteRole = (state: RootState) =>
  state.account.userSiteRole;
export const selectUserDeactivate = (state: RootState) =>
  state.account.userDeactivate;
export const selectUserDeactivateRequestDate = (state: RootState) =>
  state.account.userDeactivateRequestDate;
export const selectBusinessName = (state: RootState) =>
  state.account.businessName;
export const selectUserBusinessRole = (state: RootState) =>
  state.account.userBusinessRole;
export const selectBusinesses = (state: RootState) => state.account.businesses;
export const selectBusinessConfig = (state: RootState) =>
  state.account.businessConfig;
export const selectIsTrial = (state: RootState) => {
  return (
    state.account.businessConfig?.subscription?.subscription_type === "trial"
  );
};
export const selectIsTrialPin = (state: RootState) => {
  return (
    state.account.businessConfig?.subscription?.subscription_type ===
    "trial_pin"
  );
};
export const selectUserType = (state: RootState) => {
  return state.account.auth?.user?.data?.user_type;
};
export const selectUniqueId = (state: RootState) => state.account.uniqueId;

// Async Thunks
export const loadBusinessConfig = createAsyncThunk<
  void,
  number,
  { state: RootState }
>("account/loadBusinessConfig", async (businessId, thunkAPI) => {
  const state = thunkAPI.getState();
  const host = process.env.REACT_APP_API_HOST;
  const url = host + "/api/businesses/" + businessId + "/config";
  try {
    const response = await fetch(url, {
      headers: {
        Authorization:
          state.account.accessTokenType + " " + state.account.accessToken,
      },
    });
    if (!response.ok) {
      return thunkAPI.rejectWithValue("Error loading business config");
    } else {
      const data = await response.json();
      thunkAPI.dispatch(setBusinessConfig(data));
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
