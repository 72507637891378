const common = {
  google_oauth_client_id:
    "747020958768-fvm8p034327vcbjhms5ctipc8dq8ns7p.apps.googleusercontent.com",
  base_url: process.env.REACT_APP_API_HOST,
  base_web_url: process.env.REACT_APP_WEB_HOST,
};

const prod = {
  ...common,
  is_dev: false,
  getFullUrl: (path) => `${prod.base_web_url}${path}`,
};

const dev = {
  ...common,
  is_dev: true,
  getFullUrl: (path) => `${dev.base_web_url}${path}`,
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
