import { parsePhoneNumber } from "awesome-phonenumber";

export const isNumericInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

export const isModifierKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

export const enforceFormat = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const formatToPhoneEvent = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (isModifierKey(event)) {
    return;
  }
  const target = event.target as HTMLInputElement;

  const input = target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    target.value = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${areaCode}`;
  }
};

export const formatToPhone = (phone: string) => {
  const input = phone.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `(${areaCode}`;
  } else {
    return "";
  }
};

export const formatE164ToUSPhone = (e164: string) => {
  const pn = parsePhoneNumber(e164);
  if (pn.valid) {
    if (pn.countryCode !== 1) {
      return e164;
    }
  } else {
    return e164;
  }
  const input = e164.substring(2);
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `(${areaCode}`;
  } else {
    return "";
  }
};

export const formatUSPhoneToE164 = (usPhone: string) => {
  const input = usPhone.replace(/\D/g, "");
  return `+1${input}`;
};

// Note that to use these, you must have placeholders for your inputs
export const formValidationFieldClass = (
  formValidated: boolean,
  classes: string,
  style = "border-red-500",
) => {
  // split style by space, and add "invalid:" to each element
  const styleSplit = style.split(" ");
  const invalidStyle = styleSplit.map((s) => `invalid:${s}`).join(" ");
  const invalidStylePlaceholder = styleSplit
    .map((s) => `invalid:[&:not(:placeholder-shown):not(:focus)]:${s}`)
    .join(" ");
  return formValidated
    ? `${invalidStyle} ${classes}`
    : `${invalidStylePlaceholder} ${classes}`;
};

// To use this, you must use "peer" in your class list for the input element
export const formValidationFieldErrorClass = (
  formValidated: boolean,
  classes: string,
) => {
  return formValidated
    ? `peer-invalid:block ${classes}`
    : `peer-[&:not(:placeholder-shown):not(:focus):invalid]:block ${classes}`;
};
