export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["Trial"] = "trial";
    SubscriptionType["Personal"] = "personal";
    SubscriptionType["Pro"] = "pro";
    SubscriptionType["Team"] = "team";
    SubscriptionType["TrialPin"] = "trial_pin";
})(SubscriptionType || (SubscriptionType = {}));
export var BusinessRole;
(function (BusinessRole) {
    BusinessRole["ADMINISTRATOR"] = "Administrator";
    BusinessRole["TEAM_MEMBER"] = "Team Member";
})(BusinessRole || (BusinessRole = {}));
// Stored in the user_business table
export class UserBusinessConfig {
    role;
    constructor(role = BusinessRole.ADMINISTRATOR) {
        this.role = role;
    }
}
