import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "./store";
import { STConfig } from "@repo/common/types/lead";

export const updateLead = createAsyncThunk<
  void,
  { reset?: boolean },
  { state: RootState }
>("onboarding/updateLead", async (arg, thunkAPI) => {
  const state = thunkAPI.getState();
  const host = process.env.REACT_APP_API_HOST;
  const url = host + "/api/leads/";

  try {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lead_type: "onboarding_smooth",
        guest_id: state.onboarding.guestId,
        started_at: state.onboarding.startedAt,
        updated_at: new Date().toISOString(),
        user_agent: window.navigator.userAgent,
        greeting: state.onboarding.assistantGreeting,
        standby_number: state.onboarding.assistantStandbyNumber,
        from_number: state.onboarding.trialModeFormattedFromPhoneNumber,
        intent_to_purchase_number: state.onboarding.assistantIntentToBuyNumber,
        email: state.onboarding.accountEmail,
        name: state.onboarding.accountName,
        affiliate_id: state.onboarding.affiliateId,
        payment_succeeded: state.onboarding.paymentSucceeded,
        number_purchase_status: state.onboarding.numberPurchaseStatus,
        digits: state.onboarding.digits,
        is_react_native_webview: window.tokenFromReactNative ? true : false,
        receptionist_type: state.onboarding.receptionistType,
        reset_at: arg?.reset ? new Date().toISOString() : null,
        hs_config: state.onboarding.hsConfig,
        assistant_step: state.onboarding.assistantStep,
        campaign_id: state.onboarding.campaignId,
        screen_details: {
          screen_height: window.screen.height,
          screen_width: window.screen.width,
          window_inner_height: window.innerHeight,
          window_inner_width: window.innerWidth,
          window_screen_left: window.screenLeft,
          window_screen_top: window.screenTop,
        },
        st_config: state.onboarding.stConfig,
      }),
    });
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      console.error(e);
    }
  }
});

export const finalizeLeadAndResetOnboardingState = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("onboarding/finalizeLeadAndResetOnboardingState", async (_arg, thunkAPI) => {
  await thunkAPI.dispatch(updateLead({ reset: true }));
  thunkAPI.dispatch(resetOnboardingState());
});

export const purchaseNumber = createAsyncThunk<
  string,
  void,
  { state: RootState }
>("onboarding/purchaseNumber", async (_arg, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.onboarding.numberPurchaseStatus === "idle") {
    thunkAPI.dispatch(setNumberPurchaseStatus("loading"));
    const host = process.env.REACT_APP_API_HOST;
    const url = host + "/api/leads/twilio_create_phone";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        business_id: state.onboarding.businessId,
        phone_number: state.onboarding.assistantIntentToBuyNumber,
        current_phone: state.onboarding.assistantStandbyNumber,
        email: state.onboarding.accountEmail,
        website: state.onboarding.accountUrl,
        dry_run: process.env.NODE_ENV === "production" ? false : true,
      }),
    });
    if (!response.ok) {
      thunkAPI.dispatch(setNumberPurchaseStatus("failed"));
      return thunkAPI.rejectWithValue("Error purchasing number");
    } else {
      thunkAPI.dispatch(setNumberPurchaseCompleted(true));
      thunkAPI.dispatch(setNumberPurchaseStatus("succeeded"));
      return thunkAPI.fulfillWithValue("Number purchased");
    }
  }
  return thunkAPI.fulfillWithValue("Number already purchased");
});

function makeDigits(length = 6) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export type OnboardingState = {
  guestId: string;
  affiliateId: string | null;
  startedAt: string;
  setupAccountType: number;
  accountName: string;
  accountEmail: string;
  accountPhone: string;
  accountBusiness: string;
  accountUrl: string;
  businessId: number | null;
  userId: number | null;
  assistantStep: number;
  assistantStandbyNumber: string;
  assistantGreeting: string;
  trialModeFromPhoneNumber: string;
  trialModeFormattedFromPhoneNumber: string;
  trialModeAssistantPhoneNumber: string;
  assistantIntentToBuyNumber: string;
  assistantDescription: string;
  paymentSucceeded: boolean;
  numberPurchaseStatus: "idle" | "loading" | "succeeded" | "failed";
  movedToNextStepAfterPayment: boolean;
  selectedBotPhoneNumber: string;
  numberPurchaseCompleted: boolean;
  simpleTokenData: any;
  step: number;
  digits: string;
  receptionistType: string;
  campaignId: string | null;
  hsConfig: any;
  stConfig?: STConfig;
};

const INITIAL_STATE: OnboardingState = {
  guestId: uuidv4(),
  affiliateId: null,
  startedAt: new Date().toISOString(),
  setupAccountType: 0,
  accountName: "",
  accountEmail: "",
  accountPhone: "",
  accountBusiness: "",
  accountUrl: "",
  businessId: null,
  userId: null,
  assistantStep: 0,
  assistantStandbyNumber: "",
  assistantGreeting: "",
  trialModeFromPhoneNumber: "",
  trialModeFormattedFromPhoneNumber: "",
  trialModeAssistantPhoneNumber: "",
  assistantIntentToBuyNumber: "",
  assistantDescription: "",
  paymentSucceeded: false,
  numberPurchaseStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  movedToNextStepAfterPayment: false,
  selectedBotPhoneNumber: "",
  numberPurchaseCompleted: false,
  simpleTokenData: null,
  step: 0,
  digits: makeDigits(),
  receptionistType: "",
  campaignId: null,
  hsConfig: null,
};

export const slice = createSlice({
  name: "onboarding",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => INITIAL_STATE);
  },
  reducers: {
    setAffiliateId: (state, action) => {
      state.affiliateId = action.payload;
    },
    nextStep: (state) => {
      state.step += 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setSetupAccountType: (state, action) => {
      state.setupAccountType = action.payload;
    },
    setAccountName: (state, action) => {
      state.accountName = action.payload;
    },
    setAccountEmail: (state, action) => {
      state.accountEmail = action.payload;
    },
    setAccountPhone: (state, action) => {
      state.accountPhone = action.payload;
    },
    setAccountBusiness: (state, action) => {
      state.accountBusiness = action.payload;
    },
    setAccountUrl: (state, action) => {
      state.accountUrl = action.payload;
    },
    assistantNextStep: (state) => {
      state.assistantStep += 1;
    },
    assistantSetStep: (state, action) => {
      state.assistantStep = action.payload;
    },
    assistantSetStandbyNumber: (state, action) => {
      state.assistantStandbyNumber = action.payload;
    },
    trialModeSetFromPhoneNumber: (state, action) => {
      state.trialModeFromPhoneNumber = action.payload;
    },
    trialModeSetFormattedFromPhoneNumber: (state, action) => {
      state.trialModeFormattedFromPhoneNumber = action.payload;
    },
    trialModeSetAssistantPhoneNumber: (state, action) => {
      state.trialModeAssistantPhoneNumber = action.payload;
    },
    assistantSetGreeting: (state, action) => {
      state.assistantGreeting = action.payload;
    },
    assistantSetIntentToBuyNumber: (state, action) => {
      state.assistantIntentToBuyNumber = action.payload;
    },
    assistantSetDescription: (state, action) => {
      state.assistantDescription = action.payload;
    },
    setBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setPaymentSucceeded: (state, action) => {
      state.paymentSucceeded = action.payload;
    },
    setSelectedBotPhoneNumber: (state, action) => {
      state.selectedBotPhoneNumber = action.payload;
    },
    setMovedToNextStepAfterPayment: (state, action) => {
      state.movedToNextStepAfterPayment = action.payload;
    },
    setNumberPurchaseCompleted: (state, action) => {
      state.numberPurchaseCompleted = action.payload;
    },
    setNumberPurchaseStatus: (state, action) => {
      state.numberPurchaseStatus = action.payload;
    },
    setSimpleTokenData: (state, action) => {
      state.simpleTokenData = action.payload;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    maybeUpdateDigits: (state) => {
      // if undefined or null
      if (!state.digits) {
        state.digits = makeDigits();
      }
    },
    setReceptionistType: (state, action) => {
      state.receptionistType = action.payload;
    },
    setHSServiceAreaConfig: (state, action) => {
      state.hsConfig = {
        ...(state.hsConfig ? state.hsConfig : {}),
        service_area_config: action.payload,
      };
    },
    setHSServicesConfig: (state, action) => {
      state.hsConfig = {
        ...(state.hsConfig ? state.hsConfig : {}),
        services_config: action.payload,
      };
    },
    setHSQuotesConfig: (state, action) => {
      state.hsConfig = {
        ...(state.hsConfig ? state.hsConfig : {}),
        quotes_config: action.payload,
      };
    },
    setHSAppointmentConfig: (state, action) => {
      state.hsConfig = {
        ...(state.hsConfig ? state.hsConfig : {}),
        appointments_config: action.payload,
      };
    },
    setSTConfig: (state, action: PayloadAction<STConfig>) => {
      state.stConfig = action.payload;
    },
    noop: (state) => {
      return state;
    },
    resetOnboardingState: () => {
      return {
        ...INITIAL_STATE,
        guestId: uuidv4(),
        startedAt: new Date().toISOString(),
      };
    },
  },
});

export const {
  setAffiliateId,
  nextStep,
  setStep,
  setAccountName,
  setAccountEmail,
  setAccountPhone,
  setAccountBusiness,
  setAccountUrl,
  setSetupAccountType,
  assistantNextStep,
  assistantSetStep,
  assistantSetStandbyNumber,
  trialModeSetFromPhoneNumber,
  trialModeSetFormattedFromPhoneNumber,
  trialModeSetAssistantPhoneNumber,
  assistantSetGreeting,
  assistantSetIntentToBuyNumber,
  assistantSetDescription,
  setBusinessId,
  setUserId,
  setPaymentSucceeded,
  setSelectedBotPhoneNumber,
  setMovedToNextStepAfterPayment,
  setNumberPurchaseCompleted,
  setNumberPurchaseStatus,
  setSimpleTokenData,
  setCampaignId,
  maybeUpdateDigits,
  setReceptionistType,
  setHSServiceAreaConfig,
  setHSServicesConfig,
  setHSQuotesConfig,
  setHSAppointmentConfig,
  setSTConfig,
  noop,
  resetOnboardingState,
} = slice.actions;

export const selectCampaignId = (state: RootState) =>
  state.onboarding.campaignId;
export const selectAssistantStepState = (state: RootState) =>
  state.onboarding.assistantStep;
export const selectHSConfig = (state: RootState) => state.onboarding.hsConfig;
export const selectSTConfig = (state: RootState) => state.onboarding.stConfig;

export default slice.reducer;
