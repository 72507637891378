import React from "react";
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { store, persistor } from "./app/store";
import { FullPageSpinner } from "./ui2/common/FullPageSpinner";

const App = lazy(() => import("./App"));
const Provider = lazy(() =>
  import("react-redux").then((module) => ({ default: module.Provider })),
);
const PersistGate = lazy(() =>
  import("redux-persist/integration/react").then((module) => ({
    default: module.PersistGate,
  })),
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Suspense fallback={<FullPageSpinner />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
);
